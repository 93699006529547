import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.css']
})
export class IconBoxComponent {

  @Input()
  public set(profile: any): any {
    this.setIconBoxes(profile);
  }

  iconBox = [
    {
      title: 'Location',
      description: 'Gold Coast, Australia',
      icon_class: 'ti-location-pin',
    },
    {
      title: 'Email',
      description: 'alex@mamaev.net',
      icon_class: 'ti-email',
    }
  ];

  constructor() {
  }

  private setIconBoxes(profile: any): void {
    this.iconBox = [
      {
        title: 'Location',
        description: profile.location,
        icon_class: 'ti-location-pin',
      },
      {
        title: 'Email',
        description: profile.email,
        icon_class: 'ti-email',
      }
    ];
  }
}

