<div class="timeline" *ngFor="let timeline of data">
  <div class="box-left">
    <h4 class="title primary">{{timeline.year}}</h4>
    <span>{{timeline.organization_name}}</span>
  </div>
  <div class="box-right">
    <h6 class="title text-uppercase mb-4 pt-1">{{timeline.title}}</h6>
    <div *ngFor="let line of timeline.description">
      <p *ngIf="line.includes('#')" class="bold">{{line.slice(1)}}</p>
      <p *ngIf="!line.includes('#')">{{line}}</p>
    </div>
  </div>
</div>
