<!--<app-loading></app-loading>-->
<div class="page-wraper">
  <!-- header -->
  <app-header1></app-header1>
  <!-- header END -->
  <app-page-banner [data]="pageBanner"></app-page-banner>
  <app-social-bar></app-social-bar>

  <div class="content-body">
    <div class="content-body-inner">

      <!-- About Info -->
      <div class="section-full">
        <div class="row">
          <div class="col-xl-6 col-lg-12">
            <div class="sticky-top m-lg-b30">
              <img src="assets/images/background/{{member.image}}" alt=""/>
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 m-b30">
            <div class="section-head">
              <h2 class="title text-uppercase">
                <span class="text-gradient">{{member.name}}</span>
              </h2>
              <p class="mb-0" [innerHTML]="member.aboutMe"></p>
            </div>

            <app-line [data]="mastery"></app-line>
            <!-- TODO wire this button to CV generator or just CV file from Drive -->
<!--            <div class="m-t50 m-xs-t30">-->
<!--              <a routerLink="/resume-{{member.profile}}" class="site-button button-gradient button-md radius-no">Download CV</a>-->
<!--            </div>-->
          </div>
        </div>
      </div>

      <div class="section-full content-inner-1">
        <div class="section-head">
          <h2 class="title text-uppercase">SKILLS</h2>
        </div>

        <app-tags [data]="skills"></app-tags>
      </div>

      <!-- About Service -->
      <div class="section-full content-inner-1">
        <div class="section-head">
          <h2 class="title text-uppercase">EXPERIENCE</h2>
        </div>

        <app-timeline [data]="experience"></app-timeline>
      </div>

      <!-- <div class="section-full content-inner-1">
        <div class="section-head">
          <h2 class="title text-uppercase">SIDE PROJECTS</h2>
        </div>

        <app-circle [data]="languages"></app-circle>
      </div> -->

      <div class="section-full content-inner-1">
        <div class="resume-tag">
          <div class="resume-tag-btn">
            <strong>STUDY : </strong>
            <span>{{member.study}}</span>
          </div>

          <div class="resume-tag-btn">
            <strong>DEGREE : </strong>
            <span>{{member.highestDegree}}</span>
          </div>

          <div class="resume-tag-btn">
            <strong>{{member.studyPeriod}}</strong>
            <span> - {{member.wam}}</span>
          </div>
        </div>
      </div>

      <div class="section-full content-inner-1">
        <app-icon-box></app-icon-box>
      </div>
    </div>
  </div>
</div>
