import {Component} from '@angular/core';
import { ExperienceItem } from 'src/app/interfaces/experience-item';
import { LanguagesItem } from 'src/app/interfaces/languages-item';
import { MasteryItem } from 'src/app/interfaces/mastery-item';

@Component({
  selector: 'app-web-developer-resume',
  templateUrl: './web-developer-resume.component.html',
  styleUrls: ['./web-developer-resume.component.css']
})
export class WebDeveloperResumeComponent {

  public pageBanner = {
    title: 'Resume',
    profile: 'web-developer',
  };

  public member = {
    image: 'Profile.jpg',
    logo: '',
    designation: '',
    age: this.currentAge('1986-05-10'),
    dob: '05.10.1986',
    residence: 'Australia',
    location: 'GOLD COAST, AUSTRALIA',
    email: 'ALEX@MAMAEV.NET',
    phone: '----',
    skype: '----',
    whatsapp: '',
    aboutMe: 'Hi! My name is <span class="text-primary">Alex Mamaev</span>. I am a Software Engineer.' +
      '<br>After leading successful branding campaigns in the creative agency world for over a decade, I leveraged my design sensibility and problem-solving skills to pivot into web development.' +
      '<br>I\'m a passionate software engineer who thrives on turning complex challenges into intuitive and delightful digital experiences.' +
      '<br>' +
      '<br>I play saxophone, guitar and space drum, ride Onewheel electric board and love Kite Surfing. I am keen to join a team, of smart and open minded people.',
    interests: 'KITESURFING, ACOUSTIC GUITAR',
    name: 'Alex Mamaev',
    study: 'ACADEMY OF ARCHITECTURE AND CIVIL ENGINEERING',
    studyPeriod: '2004 - 2009',
    highestDegree: 'BACHELOR',
    wam: 'WAM 92/100',
    profile: 'web-developer'
  };

  public mastery: MasteryItem[] = [
    {
      title: 'Angular',
      score: '96',
    },
    {
      title: 'TypeScript / JavaScript',
      score: '92',
    },
    {
      title: 'HTML / CSS',
      score: '87',
    },
    {
      title: 'UI/UX design',
      score: '86',
    },
  ];

  public experience: ExperienceItem[] = [
    {
      title: 'Software Engineer - Full stack',
      year: '2021',
      organization_name: 'Findex / Visory',
      description: [
        '#Responsibilities:',
        'Spearheading development of two sophisticated cloud-based enterprise projects utilizing Angular, NodeJS (TypeScript), AWS Lambda, and DynamoDB.',
        'Actively engaged in Product Backlog Refinement (PBR) planning sessions, contributing insights into business processes and tasks.',
        'Participating in architectural discussions and facilitating Scrum ceremonies such as sprint planning and retrospective meetings.',
        'Providing coaching and support for newly hired developers, aiding in their onboarding process and ensuring swift integration into project workflows.',
        'Playing a key role in documentation efforts, ensuring comprehensive and up-to-date resources for project stakeholders.',
        'Advocating for the interests of the team, consistently seeking opportunities to improve the developer experience and foster a more efficient and enjoyable working environment for all team members.',
        '#Accomplishments:',
        'Championed the adoption and enforcement of a comprehensive code style guide, ensuring consistency and readability across projects.',
        'Implemented global ESLint rules and integrated Prettier to enhance code quality and maintainability.',
        'Elevated project robustness by introducing unit tests for the Front End, bolstering reliability and facilitating smoother deployment.',
        'Orchestrated the implementation of ratchet in GitHub Actions, enabling incremental improvements in test code coverage over time.',
      ]
    },
    {
      title: 'Web Developer / Front End - Angular',
      year: '2021-2021',
      organization_name: 'Rinami',
      description: [
        'Orchestrated Front End Development for an entire application, overseeing design, implementation, and optimization to deliver seamless functionality and exceptional user experiences.',
        'Provided mentorship and support to newly recruited developers, facilitating their onboarding process and ensuring their smooth assimilation into project workflows.',
        'Introduced and integrated a UI library into the project after conducting thorough analysis, aligning perfectly with the company\'s requirements and significantly enhancing the project\'s user interface and developer experience.',
        'Successfully migrated two commercial applications from Angular 4 to Angular 12, ensuring compatibility and leveraging the latest features.',
        'Executed full UI reskin for two commercial Progressive Web Applications (PWAs), enhancing visual appeal and usability.',
        'Led the migration of the company website to Firebase, optimizing performance and scalability.',
      ]
    },
    {
      title: 'Web Developer / Full Stack - MEAN',
      year: '2019-2021',
      organization_name: 'In The Code',
      description: [
        'Played a pivotal role in developing two enterprise projects using the MEAN stack (MongoDB, Express, Angular, NodeJS) and a mobile project employing Dart, Flutter, and Firebase.',
        'Contributed to the development of a mobile application built on Flutter and Dart, enriching the company\'s product portfolio.',
        'Facilitated coaching for entry-level developers, supporting their onboarding journey and nurturing their professional growth within the team.',
        'Provided valuable design advice that contributed to time-saving solutions for feature development, thereby optimizing business processes and enhancing efficiency.',
        'Innovated a streamlined component creation process, saving significant time and enhancing productivity on a daily basis.',
      ]
    },
    {
      title: 'Visual Content Manager',
      year: '2015-2019',
      organization_name: 'Top Snap',
      description: [
        'Orchestrated several major enhancements to the company\'s custom-built web platform, overseeing the entire process from ideation to implementation. Led cross-functional teams in optimizing performance, demonstrating strong project management and leadership skills.',
        'Successfully researched, strategized, and executed the integration of a video product into the company\'s services. Provided comprehensive training to photographers and editors, ensuring smooth adoption and proficiency',
        'Established and mentored a team of overseas suppliers, fostering a collaborative environment and enhancing operational efficiency.',
        'Implemented feedback mechanisms and performance evaluations to continuously improve team performance and maintain quality benchmarks.',
      ]
    },
  ];

  public languages: LanguagesItem[] = [
    {
      title: 'English',
      score: 88,
    },
    {
      title: 'Russian',
      score: 98,
    },
  ];

  public skills = [
    'Type Script',
    'JavaScript',
    'Dart',
    'HTML5',
    'SCSS',
    'AWS',
    'Angular',
    'Flutter',
    'Node JS',
    'Mongo DB',
    'Dynamo DB',
    'GIT',
    'Jira',
  ];

  public currentAge(birthday: string): string {
    return Math.floor((new Date().getTime() - new Date(birthday).getTime()) / 3.15576e+10).toString();
  }
}
