<header class="site-header">
    <!-- main nav -->
    <div class="header-nav icon-menu active">
      <ul class="nav navbar-nav">
        <li *ngFor="let menuData of menuItem" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a routerLink="/{{menuData.link}}">
            <i class="{{menuData.class}}"></i>
          </a>
        </li>
      </ul>
    </div>
</header>
