import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {WebDeveloperIndexComponent} from './web-developer/web-developer-index/web-developer-index.component';
import {WebDeveloperResumeComponent} from './web-developer/web-developer-resume/web-developer-resume.component';

const routes: Routes = [
  {path: '', component: WebDeveloperIndexComponent},
  {path: 'cv', component: WebDeveloperResumeComponent},

  {path: '**', redirectTo: ''},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
