<div class="row">
  <div class="col-6 m-lg-b20">
    <div class="radial-progress-box">
      <svg class="radial-progress" [attr.data-percentage]="data[0].score" viewBox="0 0 80 80">
        <circle class="incomplete" cx="40" cy="40" r="35"></circle>
        <circle class="complete" cx="40" cy="40" r="35"  [style.stroke-dashoffset]="(115 - data[0].score).toString()"></circle>
        <text class="percentage" x="50%" y="58%" transform="matrix(0, 1, -1, 0, 80, 0)">{{data[0].score + '%'}}</text>
      </svg>
      <div class="icon-content">
        <h5 class="title">{{data[0].title}}</h5>
      </div>
    </div>
  </div>
  <div class="col-6 m-lg-b20">
    <div class="radial-progress-box">
      <svg class="radial-progress" [attr.data-percentage]="data[1].score" viewBox="0 0 80 80">
        <circle class="incomplete" cx="40" cy="40" r="35"></circle>
        <circle class="complete" cx="40" cy="40" r="35" [style.stroke-dashoffset]="(102 - data[1].score).toString()"></circle>
        <text class="percentage" x="50%" y="58%" transform="matrix(0, 1, -1, 0, 80, 0)">{{data[1].score + '%'}}</text>
      </svg>
      <div class="icon-content">
        <h5 class="title">{{data[1].title}}</h5>
      </div>
    </div>
  </div>
</div>
