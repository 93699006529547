import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Header1Component} from './elements/header/header1/header1.component';
import {PageBannerComponent} from './elements/banner/page-banner/page-banner.component';
import {SocialBarComponent} from './elements/social-bar/social-bar.component';
import {TimelineComponent} from './elements/timeline/timeline.component';
import {CircleComponent} from './elements/progress-bar/circle/circle.component';
import {LineComponent} from './elements/progress-bar/line/line.component';
import {TagsComponent} from './elements/tags/tags.component';
import {IconBoxComponent} from './elements/icon-box/icon-box.component';
import {LoadingComponent} from './elements/loading/loading.component';

import {WebDeveloperIndexComponent} from './web-developer/web-developer-index/web-developer-index.component';
import {WebDeveloperResumeComponent} from './web-developer/web-developer-resume/web-developer-resume.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Page404Component} from './page404/page404.component';

@NgModule({
  declarations: [
    AppComponent,
    Header1Component,
    PageBannerComponent,
    SocialBarComponent,
    TimelineComponent,
    CircleComponent,
    LineComponent,
    TagsComponent,
    IconBoxComponent,
    LoadingComponent,

    WebDeveloperIndexComponent,
    WebDeveloperResumeComponent,

    Page404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
