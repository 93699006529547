<div class="dlab-bnr-inr text-center" [attr.data-content]='data.title'>
  <div class="container">
    <div class="dlab-bnr-inr-entry align-m text-center">
      <h1 class="text-white">{{data.title}}</h1>
      <!-- Breadcrumb row -->
      <div class="breadcrumb-row">
        <ul class="list-inline">
          <li><a href="index-{{data.profile}}">Home</a></li>
          <li>{{data.title}}</li>
        </ul>
      </div>
      <!-- Breadcrumb row END -->
    </div>
  </div>
</div>
