import {Component} from '@angular/core';

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component {

  public menuItem: any = [
    {
      title: 'home',
      link: '',
      class: 'ti-home'
    },
    {
      title: 'cv',
      link: 'cv',
      class: 'ti-user'
    }
  ];

  constructor() {
  }
}
