<div class="row sp40">
  <div *ngFor="let icons of iconBox" class="col-12 col-sm-6">
    <div class="icon-bx-wraper service-box m-b40 contact-box" [attr.data-content]='icons.title'>
      <div class="icon-content text-nowrap">
        <h4 class="dlab-tilte text-uppercase"><i class="{{icons.icon_class}} text-gradient"></i>{{icons.title}}</h4>
        <p [innerHTML]="icons.description"></p>
      </div>
    </div>
  </div>
</div>
