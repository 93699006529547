<!--<app-loading></app-loading>-->
<div class="page-wraper">
  <!-- header -->
  <app-header1></app-header1>
  <!-- header END -->
  <div class="banner-section coad-bnr">
    <canvas id="c" class="coad-bg"></canvas>
    <div class="banner-inner bnr-left">
      <h3 class="sub-title"><span class="text-gradient">Alex Mamaev</span></h3>
      <h2 class="title">Software Engineer</h2>
      <a [routerLink]="linkToCv" class="site-button outline button-md outline-half">
        <span class="check-cv">Check My Cv</span>
      </a>
      <img src="assets/images/background/profile-b.png" class="main-content" alt=""/>
    </div>
  </div>
</div>
