import {Component, OnInit, ViewEncapsulation} from '@angular/core';

declare var jQuery: any;
declare var chinese: any;

@Component({
  selector: 'app-web-developer-index',
  templateUrl: './web-developer-index.component.html',
  styleUrls: ['./web-developer-index.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebDeveloperIndexComponent implements OnInit {

  profile: any = {
    logo: 'logo-5.png',
    profile: 'web-developer'
  };

  public linkToCv = 'cv';


  constructor() {
  }

  ngOnInit(): void {
    this.matrixRain();
  }

  private matrixRain(): void {
    /* setTimeout(function(){ */
    const c: any = document.getElementById('c');
    const ctx = c.getContext('2d');

    // making the canvas full screen
    c.height = window.innerHeight;
    c.width = window.innerWidth;

    // chinese characters - taken from the unicode charset
    const charSet = 'df65g4ad6hgadh331687hat646n4f644865g6satfd5h4asr6tynm4s9yfj8';
    // converting the string into an array of single characters
    const charsForMatrix = charSet.split('');

    const fontSize = 14;
    const columns = c.width / fontSize; // number of columns for the rain
    // an array of drops - one per column
    const drops = [];
    // x below is the x coordinate
    // 1 = y co-ordinate of the drop(same for every drop initially)
    for (let x = 0; x < columns; x++) {
      drops[x] = 1;
    }

    // drawing the characters
    function draw(): void {
      // Black BG for the canvas
      // translucent BG to show trail
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, c.width, c.height);
      ctx.fillStyle = '#28638c'; // text color
      ctx.font = fontSize + 'px arial';
      // looping over drops
      for (let i = 0; i < drops.length; i++) {
        // a random chinese character to print
        const text = charsForMatrix[Math.floor(Math.random() * charsForMatrix.length)];
        // x = i*font_size, y = value of drops[i]*font_size
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        // sending the drop back to the top randomly after it has crossed the screen
        // adding a randomness to the reset to make the drops scattered on the Y axis
        if (drops[i] * fontSize > c.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        // incrementing Y coordinate
        drops[i]++;
      }
    }
    setInterval(draw, 33);
  }
}
